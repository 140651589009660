import React from 'react'
import './Parcours.css';
import Carousel from 'react-elastic-carousel' // On import le caroussel de ce qu'on vient d'installer
import Bac from '../../images/bac.jpg'
import Iut from '../../images/iut.jpg'
import Dautry from '../../images/dautry.jpg'
import IntroParcours from '../../images/parcours.jpg'





export default function Parcours() {



  return (

    <div id="parcours">
    <Carousel
    itemsToShow={1}
    className={'sliderDiv'}
    pagination={'true'}
    >

    <div className="parcours">
    <h1>Mon parcours</h1>
    <img className="sliderImg" src={IntroParcours} alt="Parcours"/>
    <br/>
    <p>
      Retraçons mon parcours jusqu'à aujourd'hui.
    </p>
    </div>

    <div>
    <h2>2015 - 2018</h2>
    <img className="sliderImg" src={Dautry} alt="Dautry"/>
    <br/>
    <p>
      J'ai effectué mes 3 années de lycée (2015-2018) au Lycée Raoul Dautry (Limoges - 87000).<br/>J'ai choisi la fillière Scientifique avec l'options Sciences de l'ingénieur en Première et Informatique et Sciences du Numérique en Terminale
    </p>
    </div>

    <div>
    <h2>2018</h2>
    <img className="sliderImg" src={Bac} alt="Bac"/>
    <br/>
    <p>
    J'ai obtenu mon Baccalauréat Scientifique SI avec mention Assez Bien.
    </p>
    </div>

    <div>
    <h2>2018 - Présent</h2>
    <img className="sliderImg" src={Iut} alt="Iut"/>
    <br/>
    <p>
      Depuis 2018, je poursuis mon cursus scolaire à l'Institut Universitaire de Technologie (IUT) du Limousin (Limoges - 87065).
    </p>
    </div>

    </Carousel>
    </div>



  );
}
