import React from 'react'
import './Hobbies.css';
import Clap from '../../images/clap.png'
import Anime from '../../images/anime.png'
import Mythe from '../../images/mythe.png'
import Football from '../../images/football.png'


export default function Hobbies() {


  return (

    <div id="hobbies">
    <h1>Mes passions</h1>
    <h2>Cinéma</h2><img className="logoHobbies" src={Clap} alt="Cinéma"/><p className="textHobbies">Je suis passionné de cinéma de tous les genres et de toutes les origines.<br/> Chaque film est une oeuvre qui permet de transmettre des émotions différentes selon la situation ou le moment de notre vie où l'on visonne ce dernier.<br/> Chacun est libre d'interprèter a sa façon un film et je trouve passionant qu'un même contenu puisse faire passer des messages différents selon les personnes concernées.</p>
    <h2>Animation japonaise</h2><img className="logoHobbies" src={Anime} alt="Animes"/><p className="textHobbies">L'animation japonaise est également un contenu que j'apprécie particulièrement, et ce pour les mêmes raisons que le cinéma.<br/> De plus, étant un contenu dessiné, le champ des possibilités pour l'histoire est extrêmement vaste, ce qui permet une très grande liberté dans le contenu de l'oeuvre.</p>
    <h2>Mythes & Légendes</h2><img className="logoHobbies" src={Mythe} alt="Mythes & Légendes"/><p className="textHobbies">Les mythes et les légendes ont toujours attisé ma curiosité, notamment à cause de l'impact que ces légendes ont encore de nos jours.</p>
    <h2>Football</h2><img className="logoHobbies" src={Football} alt="Football"/><p className="textHobbies">Grand fan de sport dans son ensemble, je suis féru de football, que j'ai pratiqué pendant 9 années en club.</p>
  </div>



  );
}
