
import './Navbar.css';
import React, { useState } from "react";
import useWindowScrollPosition from "@rehooks/window-scroll-position";

export default function Navbar() {

  function TestNav() {
    const [change, setChange] = useState(false);
    const changePosition = 400;

    let position = useWindowScrollPosition();
    // position == { x: 0, y: 0 }

    if (position.y > changePosition && !change) {
      setChange(true);
    }

    if (position.y <= changePosition && change) {
      setChange(false);
    }

    let style = {
      backgroundColor: change ? "#333" : "transparent",
      transition: "400ms ease",
      height: "50px",
      position: "fixed",
      right: 0,
      left: 0,
      top: 0,

    };

    return (
      <div style={style}>
      <div id="navbar">
      <a href="#about">A propos de moi</a>
      <a href="#skills">Mes Compétences</a>
      <a href="#projects">Mes Projets</a>
      <a href="#start">Contact & Réseaux</a>
       </div>

      </div>
    );
  }

  return (
  {/**/},

    <div className="navbar">
     <TestNav />

   </div>

  );
}
