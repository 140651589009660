import React from 'react'
import './Contact.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faGitlab } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"



const twittericon = <FontAwesomeIcon icon= {faTwitter} />

const githubicon = <FontAwesomeIcon icon= {faGithub} />

const linkedinicon = <FontAwesomeIcon icon= {faLinkedin} />

const gitlabicon = <FontAwesomeIcon icon= {faGitlab} />

const mailicon = <FontAwesomeIcon icon= {faEnvelope} />

export default function Contact() {


  return (

    <div className="social-menu">

  <ul>


    <li><a href="https://twitter.com/thomas_chatard"  target="_blank" without rel="noopener noreferrer"><i className="fa fa-twitter">{twittericon}</i></a></li>
    <li><a href="https://github.com/ThomasChatard"  target="_blank" without rel="noopener noreferrer"><i className="fa fa-github">{githubicon}</i></a></li>
    <li><a href="https://gitlab.com/ThomasChatard"  target="_blank" without rel="noopener noreferrer"><i className="fa fa-gitlab">{gitlabicon}</i></a></li>
    <li><a href="https://www.linkedin.com/in/chatard1/"  target="_blank" without rel="noopener noreferrer"><i className="fa fa-linkedin">{linkedinicon}</i></a></li>
    <li><a href="mailto:thomas.chatard@etu.unilim.fr" without rel="noopener noreferrer"><i className="fa fa-mail">{mailicon}</i></a></li>

  </ul>
</div>



  );
}
