import React from 'react'
import About from '../About/About';
import Parcours from '../Parcours/Parcours';
import Start from '../Start/Start';
import Skills from '../Skills/Skills';

import Hobbies from '../Hobbies/Hobbies';
import Navbar from '../Navbar/Navbar';
import Projects from '../Projects/Projects';
import './Body.css';



export default function Body() {


  return (

    <div id="body">
      <Navbar/>
      <Start/>
      <About/>
      <Parcours/>
      <Hobbies/>
      <Skills/>
      <Projects/>

    </div>



  );
}
