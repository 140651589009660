import React from 'react'
import './Projects.css';
import ProjetTuteure from '../../images/PT2A.png'


export default function Projects() {


  return (

    <div id="projects">
    <h1>Mes projets</h1>
    <h2>Projet Tuteuré</h2><img className="logoProject" src={ProjetTuteure} alt="Projet Tuteuré"/><p className="textProjects">Projet effectué en collaboration avec Farès CHERIF, Guillaume BREHIER, Simon MAZENOUX et Aurélien RAVAUD.<br/>Pour ce projet, nous devions créer un site pour l'Université de Limoges, permettant aux étudiants d'être mis en relation avec des entreprises étrangères afin d'y effectuer un stage.<br/>Ce projet a été effectué durant la 2ème année de DUT et est en PHP.</p>
    </div>

  );
}
