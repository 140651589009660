import React from 'react'
import './Start.css';
import avatar from '../../images/myAvatar.png'
import { Parallax } from 'react-parallax';
import Contact from '../Contact/Contact';
export default function Start() {

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  const insideStyles = {
    color : 'white',
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    justifyContent : 'center',
    alignItems : 'center'
  };

  return (


    <div id="start" style={styles}>
      <Parallax bgImage={require('../../images/BG.jpg')} strength={'700'}>
        <div style={{ height: '100vh' }}>
          <div style={insideStyles}>
            <p className="Accroche"> Bienvenue sur le portfolio de Thomas Chatard </p>
            <img className="roundedImage" src={avatar} alt="Thomas Chatard"/> <br/>
            <Contact />
          </div>

        </div>

      </Parallax>
      </div>



  );
}
