import React from 'react'
import './About.css';
import CV from '../../media/CV.pdf'




export default function About() {


  return (

    <div id="about">
      <h1>A propos de moi</h1>
      <p>
      Je me nomme Thomas Chatard, j'ai 19 ans et je suis un étudiant de 2ème année de DUT Informatique à l'IUT du Limousin.<br/><br/>
      J'ai toujours été intéréssé par le développement informatique dans son ensemble, mais lors de mon DUT je me suis découvert une passion pour le développement Web.<br/><br/>
      Je désire poursuivre ma carrière sur cette voie, que ce soit en Front-End ou en Back-End<br/><br/>
      </p>
      <a href={CV}><h1 className="CV">Mon CV</h1></a>
    </div>



  );
}
