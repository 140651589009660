import React from 'react'
import './Skills.css';
import node from '../../images/node.png'
import html from '../../images/html.png'
import css from '../../images/css.png'
import react from '../../images/react.png'
import npm from '../../images/npm.png'
import php from '../../images/php.png'
import mysql from '../../images/mysql.png'
import express from '../../images/express.png'



export default function Skils() {


  return (

    <div id="skills">
      <h1>Mes compétences</h1>
      <img className="logoSkills" src={html} alt="HTML"/> <img className="logoSkills" src={css} alt="CSS" title="CSS"/> <img className="logoSkills" src={react} alt="React"/> <img className="logoSkills" src={express} alt="Express"/>
      <img className="logoSkills" src={node} alt="Node JS"/> <img className="logoSkills" src={php} alt="PHP"/> <img className="logoSkills" src={npm} alt="NPM"/> <img className="logoSkills" src={mysql} alt="My SQL"/>
    </div>

  );
}
